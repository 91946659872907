import * as entityApi from '@/common/API/EntityApi.js'
import * as _ from 'lodash'

export const actionTypes = {
  STORE_ENTITY: 'ENTITIES/STORE_ENTITY',
  GET_ALL_ENTITYS: 'ENTITIES/GET_ALL_ENTITYS',
  GET_ENTITY_CHILDS: 'ENTITIES/GET_ENTITY_CHILDS',
  REMOVE_ENTITIES: 'ENTITIES/REMOVE_ENTITIES',
  GET_ALL_ENTITYTYPES: 'ENTITIES/GET_ALL_ENTITYTYPES',
  GET_ENTITYSETTINGS: 'ENTITIES/GET_ENTITYSETTINGS',
  GET_ENTITYSETTINGSOPTIONS: 'ENTITIES/GET_ENTITYSETTINGSOPTIONS',
  GET_ENTITIES_LOCATION: 'ENTITIES/GET_ENTITIES_LOCATION',
  GET_ALL_ENTITYSETTINGS: 'ENTITIES/GET_ALL_ENTITYSETTINGS',
  GET_ALL_ENTITYSETTINGSOPTIONS: 'ENTITIES/GET_ALL_ENTITYSETTINGSOPTIONS',
  GET_ALL_ENTITYPROPERTIES: 'ENTITIES/GET_ALL_ENTITYPROPERTIES',
  GET_ALL_MISSINGSETTINGS: 'ENTITIES/GET_ALL_MISSINGSETTINGS',
  REFRESH: 'ENTITIES/REFRESH'
}

export const mutationTypes = {
  SET_ENTITY: 'ENTITIES/SET_ENTITY',
  SET_ALL_ENTITYTYPES: 'ENTITIES/SET_ALL_ENTITYTYPES',
  SET_ALL_ENTITYS: 'ENTITIES/SET_ALL_ENTITYS',
  SET_ENTITYSETTINGS: 'ENTITIES/SET_ENTITYSETTINGS',
  CLEAR_STORE: 'ENTITIES/CLEAR_STORE',
  SET_ENTITYSETTINGSOPTIONS: 'ENTITIES/SET_ENTITYSETTINGSOPTIONS',
  SET_ENTITYPROPERTIES: 'ENTITIES/SET_ENTITYPROPERTIES',
  SET_REMOVEDENTITIES: 'ENTITIES/SET_REMOVEDENTITIES',
  SET_MISSINGSETTINGS: 'ENTITIES/SET_MISSINGSETTINGS'
}

const state = {
  entities: [],
  removedEntities: [],
  entityTypes: null,
  entitySettings: null,
  entitySettingsOptions: null,
  entityProperties: null,
  missingSettings: null
}

export const mappedFunctionOptions = {
  // Intramuraal
  'a3aa5a27-0ac5-4eb2-938e-45db6926dedd': [
    'c7d9016d-377d-4052-b757-a291edb6e2af',
    'b20e0079-16d3-43c4-8508-e9a4504beec9',
    '4fe0c545-ee33-45bc-8e83-5596bc6092c7',
    'ca36c55a-d67c-434c-a6b6-efbbdccc0055',
    '11ec553b-6fdb-4963-8830-ce5594c0a5b3',
    '91f304f7-c1d6-4b6c-9065-358c06e150fc',
    'fb94f9cc-90b9-4bd1-b61c-75ba9d03f432',
    '4353da6a-4314-4ab7-b71c-8777682464b6',
    '65af8ac5-7f2f-40a0-b026-c04f89c9deb3',
    '63866e37-1385-44ef-817e-1fa80835b4a7',
    'f7d6bed0-9a5f-4132-b18d-2bb0098b342d',
    '7ec2980c-8660-47c1-b362-742514bc94da',
    'deded1e2-0d46-447b-88dd-df304d3eba98',
    '53dc9916-d287-473b-8e93-21620f8e1855',
    'b83f6f85-deb8-48e3-9c19-3dcdd9d06f5d',
    '021c3ea2-a533-46a7-9f72-728c2cca740f',
    '9525f1ac-c0ec-4bde-82ff-17dd275d9d62',
    '602081bb-f28f-4a2d-a5ba-177dae3c8d79',
    'ed23ab68-787b-4d90-89ba-47b479d3e241',
    '67515a9f-f0c9-44a8-9f94-255b4001f5ff'
  ],
  // Extramuraal
  '531086c2-bce4-46a9-b77d-45abbab31c4b': [
    'c7d9016d-377d-4052-b757-a291edb6e2af',
    'b20e0079-16d3-43c4-8508-e9a4504beec9',
    '4fe0c545-ee33-45bc-8e83-5596bc6092c7',
    'ca36c55a-d67c-434c-a6b6-efbbdccc0055',
    '11ec553b-6fdb-4963-8830-ce5594c0a5b3',
    '91f304f7-c1d6-4b6c-9065-358c06e150fc',
    'fb94f9cc-90b9-4bd1-b61c-75ba9d03f432',
    '4353da6a-4314-4ab7-b71c-8777682464b6',
    '65af8ac5-7f2f-40a0-b026-c04f89c9deb3',
    '63866e37-1385-44ef-817e-1fa80835b4a7',
    'f7d6bed0-9a5f-4132-b18d-2bb0098b342d',
    '7ec2980c-8660-47c1-b362-742514bc94da',
    'deded1e2-0d46-447b-88dd-df304d3eba98',
    '53dc9916-d287-473b-8e93-21620f8e1855',
    'b83f6f85-deb8-48e3-9c19-3dcdd9d06f5d',
    '021c3ea2-a533-46a7-9f72-728c2cca740f',
    '9525f1ac-c0ec-4bde-82ff-17dd275d9d62',
    '602081bb-f28f-4a2d-a5ba-177dae3c8d79',
    'ed23ab68-787b-4d90-89ba-47b479d3e241',
    '67515a9f-f0c9-44a8-9f94-255b4001f5ff'
  ],
  // Utiliteitsgebouw
  '5b22f257-f947-4472-b396-8eccf00eb460': [
    '9525f1ac-c0ec-4bde-82ff-17dd275d9d62',
    '602081bb-f28f-4a2d-a5ba-177dae3c8d79',
    'e1bc0e3e-3287-457f-a8a5-9bdc29c5b3bb',
    'e68fd24e-f272-44f1-9312-bb27971569f0',
    'b83f6f85-deb8-48e3-9c19-3dcdd9d06f5d',
    '021c3ea2-a533-46a7-9f72-728c2cca740f',
    '6d6a4710-4170-47bd-bd9c-4e9d48584062',
    '46611c2d-3776-4330-9a42-b6c115c68eca',
    'a96bae92-bb63-4645-8e26-ced0cbeb7b69',
    '548ae6de-12f7-4396-be0d-0775b9727f22',
    'a0fae56d-865d-4240-baec-1c2c7e54a47d',
    '2abd4f47-df22-46ff-9e82-dc99f9855f6e',
    '67515a9f-f0c9-44a8-9f94-255b4001f5ff',
    '602081bb-f28f-4a2d-a5ba-177dae3c8d79',
    'ed23ab68-787b-4d90-89ba-47b479d3e241'
  ],
  '1c8b6fa3-f56e-42c0-8be9-269b55bb367c': [
    '4fe0c545-ee33-45bc-8e83-5596bc6092c7',
    'c7d9016d-377d-4052-b757-a291edb6e2af',
    '11ec553b-6fdb-4963-8830-ce5594c0a5b3',
    'fb94f9cc-90b9-4bd1-b61c-75ba9d03f432',
    'ca36c55a-d67c-434c-a6b6-efbbdccc0055',
    'b20e0079-16d3-43c4-8508-e9a4504beec9',
    '91f304f7-c1d6-4b6c-9065-358c06e150fc',
    '4353da6a-4314-4ab7-b71c-8777682464b6',
    '270954f3-98f7-4484-af58-4bd3acac84a9',
    '253f565f-54b5-4750-8382-9b24eacb8239',
    '5147292e-4b53-4c62-aa77-82b6d27059f8',
    '3bda4760-1957-4571-a2b1-5a7ace004b81'
  ]
}

const getters = {
  entities: (state) => state.entities,
  removedEntities: (state) => state.removedEntities,
  entityTypes: (state) => state.entityTypes,
  entitySettings: (state) => state.entitySettings,
  entitySettingsOptions: (state) => state.entitySettingsOptions,
  entityProperties: (state) => state.entityProperties,
  missingSettings: (state) => state.missingSettings
}

const actions = {
  [actionTypes.GET_ENTITY_CHILDS] ({ commit }, entityId) {
    return entityApi.getEntitiesByParent(entityId).then(result => {
      commit(mutationTypes.SET_ALL_ENTITYS, result)
      return result
    })
  },
  [actionTypes.GET_ALL_ENTITYTYPES] ({ commit }) {
    return entityApi.getAllEntityTypes().then(result => {
      commit(mutationTypes.SET_ALL_ENTITYTYPES, result)
    })
  },
  [actionTypes.GET_ALL_ENTITYSETTINGS] ({ commit }) {
    return entityApi.getAllEntitySettings().then(result => {
      commit(mutationTypes.SET_ENTITYSETTINGS, result)
    })
  },
  [actionTypes.GET_ALL_ENTITYSETTINGSOPTIONS] ({ commit }) {
    return entityApi.getAllEntitySettingOptions().then(result => {
      commit(mutationTypes.SET_ENTITYSETTINGSOPTIONS, result)
    })
  },
  [actionTypes.GET_ALL_ENTITYPROPERTIES] ({ commit }) {
    return entityApi.getAllEntityProperties().then(result => {
      commit(mutationTypes.SET_ENTITYPROPERTIES, result)
    })
  },
  [actionTypes.GET_ALL_MISSINGSETTINGS] ({ commit }) {
    return entityApi.getAllMissingSettings().then(result => {
      commit(mutationTypes.SET_MISSINGSETTINGS, result)
    })
  },
  [actionTypes.STORE_ENTITY] ({ commit }, entity) {
    commit(mutationTypes.SET_ENTITY, entity)
  },
  [actionTypes.REMOVE_ENTITIES] ({ commit }, entities) {
    commit(mutationTypes.SET_REMOVEDENTITIES, entities)
  },
  [actionTypes.REFRESH] ({ commit }) {
    commit(mutationTypes.CLEAR_STORE)
  },
  [actionTypes.GET_ENTITIES_LOCATION] ({ commit }, locationId) {
    return entityApi.getEntitiesByLocation(locationId).then(result => {
      commit(mutationTypes.SET_ALL_ENTITYS, result)
      return result
    })
  }
}

const mutations = {
  [mutationTypes.SET_ALL_ENTITYTYPES] (state, entityTypes) {
    state.entityTypes = entityTypes
  },
  [mutationTypes.SET_ENTITYSETTINGS] (state, entitySettings) {
    state.entitySettings = entitySettings
  },
  [mutationTypes.SET_ENTITYSETTINGSOPTIONS] (state, entitySettingsOptions) {
    state.entitySettingsOptions = entitySettingsOptions
  },
  [mutationTypes.SET_ALL_ENTITYS] (state, result) {
    state.entities = _.unionBy(result, state.entities, 'Id')
  },
  [mutationTypes.SET_ALL_ENTITYTYPES] (state, result) {
    state.entityTypes = result
  },
  [mutationTypes.SET_ENTITYPROPERTIES] (state, entityProperties) {
    state.entityProperties = entityProperties
  },
  [mutationTypes.SET_MISSINGSETTINGS] (state, missingSettings) {
    state.missingSettings = missingSettings
  },
  [mutationTypes.SET_ENTITY] (state, entity) {
    _.remove(state.entities, ['id', entity.id])
    state.entities.push(entity)
  },
  [mutationTypes.SET_ENTITYSETTINGSOPTIONS] (state, result) {
    state.entitySettingsOptions = result
  },
  [mutationTypes.SET_REMOVEDENTITIES] (state, entities) {
    entities.forEach(e => {
      _.remove(state.entities, ['id', e.id])
      state.removedEntities.push(e)
    })
  },
  [mutationTypes.CLEAR_STORE] (state) {
    state.entities = []
    state.removedEntities = []
  }
}

export const storeModule = {
  state,
  getters,
  actions,
  mutations
}
